import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { InAppBrowser } from '@ionic-native/in-app-browser/__ivy_ngcc__/ngx';
import { EntradasService } from 'src/app/services/entradas.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'myApp-visor-entrada-ingreso',
  templateUrl: './visor-entrada-ingreso.component.html',
  styleUrls: ['./visor-entrada-ingreso.component.scss'],
})
export class VisorEntradaIngresoComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  @Input() entrada;
  @Input() evento;
  @Input() idOriginal;
  encuentro = false;
  constructor(
              private modal: ModalController,
              private iab: InAppBrowser,
              private entradaService: EntradasService,
              private loading: LoadingController,
              private datePipe: DatePipe,
              private alertController: AlertController
  ) { }

  async ngOnInit() {
    
    
  }

  async ionViewDidEnter(){
  
      console.log(this.idOriginal);
      await this.revisarIngresos().then(data =>{
        console.log(this.encuentro);
        if(!this.encuentro){
          if(this.entrada.qrs.length == this.entrada.cantidad){
            this.alertaErrorMaximo();
          }
          else{
            this.realizarIngreso();
          }
          
        }
      });
    
  
  }

  async alertaIngreso(ing) {
    const alert = await this.alertController.create({
      header: 'Error',
      message: 'Este codigo ya fue ingresado a las - ' + this.datePipe.transform(ing.fechaIngreso, 'hh:mm a'),
      buttons: ['Aceptar']
    });

    await alert.present();
  }
  async alertaIngresoExito() {
    const alert = await this.alertController.create({
      header: 'Mensaje',
      message: 'Codigo ingresado con exito!',
      buttons: ['Aceptar']
    });

    await alert.present();
  }
  async alertaErrorMaximo() {
    const alert = await this.alertController.create({
      header: 'Error',
      message: 'Ya todos los condigos fueron ingresados',
      buttons: ['Aceptar']
    });

    await alert.present();
  }


  async revisarIngresos(){
    let contador = 0;
    let ing: any;
    this.entrada.qrs.forEach(ingreso => {
        if(ingreso.codigo == this.idOriginal){
            this.encuentro = true;
            ing = ingreso;
            this.alertaIngreso(ing);
        }
        else{

        }
    });
    
  }


  cerrar(){
    this.modal.dismiss();
  }

  abrirLink(){
      this.iab.create(this.entrada.sinpe);
  }
  openWa(){
    this.iab.create('https://api.whatsapp.com/send?phone=506' + this.entrada.telefono);
  }
  guardar(){
    this.entradaService.update(this.entrada, this.evento);
    this.modal.dismiss();
  }

  realizarIngreso(){
    this.entrada.fechaIngreso = new Date().toISOString();
    this.entrada.ingreso = true;
    this.entrada.qrs.push(
      {
        fechaIngreso: new Date().toISOString(),
        codigo: this.idOriginal,
      }
    );
    this.entradaService.update(this.entrada, this.evento);
    this.alertaIngresoExito();
  }

  
}
