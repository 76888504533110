import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventoService } from 'src/app/services/evento.service';

@Component({
  selector: 'myApp-fase',
  templateUrl: './fase.component.html',
  styleUrls: ['./fase.component.scss'],
})
export class FaseComponent implements OnInit {
@Input() evento;
@Input() fase;
@Input() nuevo;
nombre = '';
precio = 0;
  constructor(private modal: ModalController, private eventoService: EventoService) { }

  ngOnInit() {}



  guardar(){
    if(this.nuevo){
      this.evento.fases.push(this.fase);
      this.eventoService.update(this.evento, this.evento.id);
      this.modal.dismiss();
    }
    else{
      this.eventoService.update(this.evento, this.evento.id);
      this.modal.dismiss();
    }
   
  }
  borrarTipo(tipo){
    let index = this.fase.tiposEntrada.findIndex(t => t.nombre == tipo.nombre);
    this.fase.tiposEntrada.splice(index, 1);
  }

  cerrar(){
    this.modal.dismiss();
  }

  async agregarTipo(){
    let nuevo = {
      nombre: this.nombre,
      precio: this.precio,

    }
    if(this.fase.tiposEntrada){
      this.fase.tiposEntrada.push(nuevo);
      this.nombre = '';
      this.precio = 0;
    }
    else{
      this.fase.tiposEntrada = [];
      this.fase.tiposEntrada.push(nuevo);
      this.nombre = '';
      this.precio = 0;
    }
  }

}
