import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'myApp-modal-pago',
  templateUrl: './modal-pago.component.html',
  styleUrls: ['./modal-pago.component.scss'],
})
export class ModalPagoComponent {
  @Input() entrada;
  @Input() tc;
  @Input() evento;
  paymentAmount: string = '';
  currency: string = 'USD';
  currencyIcon: string = '$';
  constructor(private modal3: ModalController,
             ) {
   
  }

  cerrar(){
    this.modal3.dismiss();
  }

  async ionViewWillEnter(){
    let total = this.entrada.precio * this.entrada.cantidad;
    let comision = this.evento.comision * this.entrada.cantidad;
    
    total += comision;
    let tot2 = total / this.tc;
    let tot3  = tot2.toFixed(2);
    this.paymentAmount = tot3.toString();

    this.paymentMethod()
  }

  paymentMethod(){
    let tosplit = this.entrada.nombre;
    var splitted = tosplit.split(" ", 2); 
    console.log(splitted)
    let _this = this;
    setTimeout(() => {
      // Render the PayPal button into #paypal-button-container
      <any>window['paypal'].Buttons({

        // Set up the transaction
        createOrder: function (data, actions) {
          return actions.order.create(
            {
            payer: {
              email_address: _this.entrada.email,
              name: {
              given_name: splitted[0],
              surname: splitted[1]
              },
              phone: {
              phone_number: {
              national_number: _this.entrada.telefono
              },
              },
         
        }, 
            purchase_units: [{
              amount: {
                value: _this.paymentAmount
              },
              }]
          });
        },

        // Finalize the transaction
        onApprove: function (data, actions) {
          return actions.order.capture()
            .then(function (details) {
              // Show a success message to the buyer
              alert('Transaction completed by ' + details.payer.name.given_name + '!');
              _this.modal3.dismiss({
                data: true
              });
            })
            .catch(err => {
              console.log(err);
              alert('Error al realizar pago, verifique en la parte superior del formulario el error');
            })
        }
      }).render('#paypal-button-container');
    }, 500)
  }
}
