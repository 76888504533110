import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'myApp-seleccion-entrada',
  templateUrl: './seleccion-entrada.component.html',
  styleUrls: ['./seleccion-entrada.component.scss'],
})
export class SeleccionEntradaComponent implements OnInit {

  constructor(private modal: ModalController) { }

  ngOnInit() {}

  cerrar(){
    this.modal.dismiss();
  }

  seleccionEntrada(entrada){
    if(entrada.cantidad <= 0){
      alert('Lo sentimos, fase agotada')
    }
    else{
      this.modal.dismiss({
        fase: entrada
      })
    }
   
  }
}
