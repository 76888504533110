import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from 'angularfire2/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { ThrowStmt } from '@angular/compiler';
import { Articulo } from '../models/articulo';
import { Cia } from '../models/cia';
import { Entradas } from '../models/entradas';

@Injectable({
  providedIn: 'root'
})
export class EntradasService {
  private usuariosCollection: AngularFirestoreCollection<Entradas>;

  private usuarios: Observable<Entradas[]>;
  private usuario: BehaviorSubject<string>;

  constructor(public db: AngularFirestore) {
   
    this.usuariosCollection = db.collection<Entradas>('tblEntradas');

    this.usuarios = this.usuariosCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a:any) => {
          const data = a.payload.doc.data();
          const key = a.payload.doc.id;
          return { key, ...data };
        });
      })
    );
  }

 
  crearId(){
   return this.db.createId();
  }

  getUsuarios() {
    return this.usuarios;
  }
  getDatosTickets(id) {
    let collection = this.db.collection<Entradas>('tblDatos');
    return collection.doc<Entradas>(id).valueChanges();
  }
  getUsuario(id) {
    return this.usuariosCollection.doc<Entradas>(id).valueChanges();
  }
  getEntrada(id) {
    let collection = this.db.collection<Entradas>('tblEntradas');
    return collection.doc<Entradas>(id).valueChanges();
  }
  getEntradaTemp(id) {
    let collection = this.db.collection<Entradas>('tblEntradasTemp');
    return collection.doc<Entradas>(id).valueChanges();
  }
  update(Cia: Entradas, id: string) {
    let collection = this.db.collection<Entradas>('tblEntradas');
    return collection.doc(Cia.codigo).update(Cia);
  }
  

  add(Cia: Entradas, id) {
    let collection = this.db.collection<Entradas>('tblEntradas');
    return collection.doc(Cia.codigo).set(Cia);
  }

  addUsuario(user) {
    
    let collection = this.db.collection<any>('tblClientes');
  
    return collection.doc(user.email).set(user);
  }

  getUsuarioCliente(id) {
    let collection = this.db.collection<any>('tblClientes');
    return collection.doc<any>(id).valueChanges();
  }

  getPorCliente(correo){
    return this.usuarios = this.db.collection<Entradas>('tblEntradas', (ref) =>
    ref.where('email', '==', correo)).valueChanges();
  }

  addTemp(Cia: Entradas, id) {
    let collection = this.db.collection<Entradas>('tblEntradasTemp');
  
    return collection.doc(Cia.codigo).set(Cia);
  }
  borrarTemp(id){

    return this.db.collection<Entradas>('tblEntradasTemp').doc(id).delete();
    
   
  }
  getPorOrganizador(id){
    return this.usuarios = this.db.collection<Entradas>('tblEntradas', (ref) =>
    ref.where('codigoEvento', '==', id).orderBy('nombre')).valueChanges();
  }
 
  borrarPorOrganizador(id, entrada){

    return this.db.collection<Entradas>('tblEntradas').doc(entrada).delete();
    
   
  }

  crearSubs(Cia){
    let collection = this.db.collection<Entradas>('submissions');
    return collection.doc(Cia.codigo).set(Cia);
    
   
  }

  crearRechazo(Cia){
    let collection = this.db.collection<Entradas>('rechazados');
    return collection.doc(Cia.codigo).set(Cia);
    
   
  }

  remove(id) {
    return this.usuariosCollection.doc(id).delete();
  }
}