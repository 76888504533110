import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/__ivy_ngcc__/ngx';
import { LoginClienteComponent } from '../login-cliente/login-cliente.component';
import { DatosService } from 'src/app/services/datos.service';
import { Subscription } from 'rxjs';
import { EntradasService } from 'src/app/services/entradas.service';


@Component({
  selector: 'myApp-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  @Input() celular;
  entradas = [];
  private subscriptions: Subscription[] = [];
  constructor(private modal: ModalController,
              private md3: ModalController,
              private iab: InAppBrowser,
              private entradaServ: EntradasService,
              public datoServ: DatosService,
              private nav: NavController) { }

  ngOnInit() {
    this.obtener();
  }

  abrirLink(link){
    this.iab.create(link);
  }

  unsubs(){
    this.subscriptions.forEach(data =>{
      data.unsubscribe();
    })
  }

  cerrar(){
    this.unsubs();
    this.md3.dismiss();
  }

  async obtener(){
    this.subscriptions.push(
      this.entradaServ.getPorCliente(this.datoServ.userLogin.email).subscribe(data =>{
        if(data){
          this.entradas = data;
        }
        else{
          alert('No cuenta con entradas disponibles');
        }
      })
    )
  }



}
