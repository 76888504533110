import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonRange, ModalController } from '@ionic/angular';
import { Howl } from 'howler';
export interface Track {
  name: string;
  path: string;
}
@Component({
  selector: 'myApp-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit {
 playList: Track[] = [
  {name: 'Lollipop - Darrel', path: '../../../assets/lol.mp3'}
 ];
 player: Howl = null;
 progress = 0;
 isPLaying = false;
  @Input() evento;
  @ViewChild('range', {static: false}) range: IonRange;
 activeTrack : Track = null;
  constructor(
              private mod: ModalController
  ) { }

  ngOnInit() {
    console.log(this.evento)
  }

  cerrar(){
    this.player.stop();
    this.mod.dismiss();
  }


  comenzar(){
    this.start(this.playList[0]);
 
  }

  start(track: Track){
    this.player = new Howl({
      src: [track.path],
    });
    this.isPLaying = true;
    this.activeTrack = track;
    this.updateProgr();
    this.player.play();
  }

  togglePLayer(pause){
    if(!this.activeTrack){
      this.comenzar()
    }
    else{
      this.isPLaying =! pause;
      if(pause){
        this.player.pause();
      }
      else{
        this.player.play();
  
      }
    }
   
  }

  seek(){
    let newValue =+ this.range.value;
    let duration = this.player.duration();
    this.player.seek(duration * (newValue / 100));
  }
  updateProgr(){
     let seek = this.player.seek();
     this.progress = (seek / this.player.duration()) * 100 || 0;
     setTimeout(() => {
      this.updateProgr();
     }, 100);
  }


  next(){
    this.player.stop();
     let index = this.playList.indexOf(this.activeTrack);
     if(index != this.playList.length -1){
      this.start(this.playList[index + 1]);
     }
     else{
      this.start(this.playList[0]);
     }
  }

  prev(){
    this.player.stop();
    let index = this.playList.indexOf(this.activeTrack);
    if(index > 0){
     this.start(this.playList[index - 1]);
    }
    else{
     this.start(this.playList[this.playList.length - 1]);
    }
  }

}
