import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { EventoService } from 'src/app/services/evento.service';

import { AngularFireStorage } from 'angularfire2/storage';
import { Evento } from 'src/app/models/evento';
@Component({
  selector: 'myApp-nuevo-evento',
  templateUrl: './nuevo-evento.component.html',
  styleUrls: ['./nuevo-evento.component.scss'],
})
export class NuevoEventoComponent implements OnInit {
 @Input() evento:Evento;


public mensajeArchivo = 'No hay un archivo seleccionado';
public datosFormulario = new FormData();
public nombreArchivo = '';
public URLPublica = '';
public porcentaje = 0;
public finalizado = false;


  constructor(private alertCtrl: AlertController,
              private modal: ModalController,
              private loadingController: LoadingController,
              private afStorage: AngularFireStorage,
              private eventoService: EventoService,) { }

  ngOnInit() {}


  async nuevoArtista() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      backdropDismiss: false,
      header: 'Nombre de Artista',
      inputs: [
        {
          name: 'artista',
          placeholder: 'Nombre de Artista'
        },
        
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Aceptar',
          handler: data => {
            if(data.artista){
              let art = {
                nombre: data.artista
              }
              this.evento.artistas.push(art);
              this.presentAlert();
             
            }
            else{

            }
          }
        }
      ]
    
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
  cerrar(){
    this.modal.dismiss();
  }
  async presentAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Alerta',

      message: 'Artista agredado al line up!',
      buttons: ['Aceptar'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
  async alertaEliminado() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Alerta',

      message: 'Artista eliminado del line up!',
      buttons: ['Aceptar'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  guardar(){
    this.eventoService.add(this.evento).then(data =>{
      alert('Evento agregado con exito');
        });
        this.modal.dismiss();
  }



  async selectFile(event){
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
       await this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        this.subirArchivo();
      }
    } else {
      this.mensajeArchivo = 'No hay un archivo seleccionado';
    }
   
  }
  
  async subirArchivo() {
    
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Subiendo imagen...',
  
    });
    await loading.present();
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.afStorage.ref('imgEventos/' + this.evento.nombre);
    await referencia.put(archivo);
    referencia.getDownloadURL().subscribe(data =>{
      if(data){
        this.evento.img = data;
      }
    });
    this.limpiarCarga();
    loading.dismiss();
  }

  limpiarCarga(){
    this.mensajeArchivo = 'No hay un archivo seleccionado';
    this.nombreArchivo = '';
    this.datosFormulario.delete('archivo');
  }

}
