import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, Platform, LoadingController, AlertController } from '@ionic/angular';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Entradas } from 'src/app/models/entradas';
import { EntradasService } from 'src/app/services/entradas.service';
import { Evento } from '../../models/evento';
import { AngularFireStorage } from 'angularfire2/storage';
import { Fase } from '../../models/fase';
import { OrganizadorService } from '../../services/organizador.service';
import { EventoService } from '../../services/evento.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

import QR from 'qrcode-base64'
import { ModalPagoComponent } from '../modal-pago/modal-pago.component';
import { PlayerComponent } from '../player/player.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-entrada-cliente',
  templateUrl: './entrada-cliente.component.html',
  styleUrls: ['./entrada-cliente.component.scss'],
})
export class EntradaClienteComponent implements OnInit {
  metodoP = '';
  totales = {
    totalEntrada: 0,
    comisionDolar: 0,
    comisionPorcentaje: 0,
    comisionTicketera: 0,
    totalPagar: 0,
    totalComisiones: 0,
    totalIva: 0,
  }

  entrada: any = {
    nombre: '',
    ingreso: false,
    telefono: '',
    codigo: '',
    cantidad: '1',
    fase: '',
    fecha: new Date().toISOString(),
    sinpe: '',
    cedula: '',
    qrs: [],
    sinpeValido: false,
    numeroReferencia: '',
    email: '',
    nombreEvento: '',
    linkPdf: '',
    organizadorNombre: '',
    precio: 0,
    lider: '',
    cortesia: false,
    codigoEvento: '',
    correoEnviado: '',
    metodoP: '',
    imgEvento: '',
   }

   
   terminos = false;
   fasesDisponibles = [];
   
  @Input() evento;
  @Input() organizador;
  @Input() lider;
  @Input() faseEvento;
  public mensajeArchivo = 'No hay un archivo seleccionado';

public datosFormulario = new FormData();
public nombreArchivo = '';
public URLPublica = '';
public porcentaje = 0;
public finalizado = false;
  tipoEntrda = '';
  pdfObj = null;
  fase = {
    fase: '',
    precio: 0,
    disponible: false,
    cantidad: 1,
    moneda: '',
    tiposEntrada: [],
    codigoPromo: '',
  }
  encodeText: string = 'asdadabds';
  encodeData: any;
  encodes = [];
  images = [];
  promoValida = false;
  codigoPromo = '';

  constructor(private modal: ModalController,
              private entradaService: EntradasService,
              private loadingController: LoadingController,
              private organizadorServ: OrganizadorService,
              private eventoServ: EventoService,
              private modal2: ModalController,
              private md3: ModalController,
              private alertController: AlertController,
              private datePipe: DatePipe,
              private afStorage: AngularFireStorage) { }

  ngOnInit() {
   
  }


  validarCodigo(){
    if(this.fase.codigoPromo == this.codigoPromo){
      this.promoValida = true;
      this.entrada.cantidad = '1';
      this.entrada.precio = 0;
      this.tipoEntrda = '';
      this.entrada.cortesia = true;
    }
    else{
      this.promoValida = false;
      this.entrada.precio = this.fase.precio;
      this.entrada.cortesia = false;
      this.alertaCodigo();
      
    }
  }

  ionViewDidEnter(){
    this.validarMetodos();
    this.validarFases();
    this.calcularTotales();

  
    this.entrada.fechaEvento = this.evento.fecha;
    this.entrada.nombreEvento = this.evento.nombre;
    this.entrada.organizadorNombre = this.evento.organizadorNombre;
    this.entrada.codigoEvento = this.evento.id;
    this.entrada.imgEvento = this.evento.img;
  }

  validarMetodos(){
    if(this.evento.sinpe == true && this.evento.tarjeta == true){
      this.metodoP = '';
    }
    if(this.evento.sinpe == false && this.evento.tarjeta == true){
      this.metodoP = 'tarjeta';

    }
    if(this.evento.tarjeta == false && this.evento.sinpe == true){
      this.metodoP = 'sinpe';
      
    }
  }

  seleccionarSinpe(){
    this.metodoP = 'sinpe';
  }
  seleccionarTarjeta(){
    this.metodoP = 'tarjeta';
  }

  async encode(id){
    // for (let i = 1; i <= parseInt(this.entrada.cantidad); i++) {
    //   var imgData = QR.drawImg(id, {
    //     typeNumber: 4,
    //     errorCorrectLevel: 'M',
    //     size: 500
    //   })
   
    //  this.encodes.push(imgData);
    // }


    var imgData = QR.drawImg(id, {
      typeNumber: 4,
      errorCorrectLevel: 'M',
      size: 500
    });
 
   this.encodeData = imgData;
  }


  prueba(event){
    
    
    let found = this.fase.tiposEntrada.find(tipo => tipo.precio == event.detail.value)

    this.tipoEntrda = found.nombre;
  }
  validarFases(){
    this.fase = this.faseEvento;
    this.entrada.fase = this.faseEvento.fase;
    this.entrada.precio = this.faseEvento.precio;
  }
  // async validarFases(){
  //   const loading = await this.loadingController.create({
  //     cssClass: 'my-custom-class',
  //     message: 'Validando disponibilidad de entradas...',
    
  //   });
  //   await loading.present();
  //   this.evento.fases.forEach(data =>{

  //     if(data.disponible){
  //       if(data.cantidad>0){
  //         this.fasesDisponibles.push(data);
  //         if(data.precio > this.fase.precio){
  //           this.fase = data;
  //           this.entrada.fase = data.fase;
  //           this.entrada.precio = data.precio

  //         }
         
  //       }
  //       else{
  //         console.log('Fase no disponible')
  //       }
  //     }
  //     else{
  //       console.log('Fase no disponible')
  //     }
  //   })
    
  //   setTimeout(() => {
  //     if(this.fasesDisponibles.length == 0){
  //       loading.dismiss();
  //       this.alertaEntradas();
  //       this.modal.dismiss();
        
  //     }
  //     else{
  //       loading.dismiss();
  //     }
  //   }, 2000);
  // }


  calcularTotales(){
    let costo = 0;
    let comisionTicket = 0;
    let precioTotal = 0;
    let comisionPagadito = 0;
    let comisionDolarPagadito = 0;
    let totalComisiones = 0;
    let totalIva = 0;
  

    costo = this.entrada.precio * this.entrada.cantidad;
    comisionTicket = this.evento.comision * this.entrada.cantidad;

    this.totales.comisionTicketera = comisionTicket
    this.totales.totalPagar = costo + comisionTicket;
    this.entrada.totales = this.totales;
    console.log(this.totales);
  
   


  }



  async createPdfNew(){
    let lista = [];
    lista.push(['', 'QR'],)
    for (let i = 1; i <= parseInt(this.entrada.cantidad); i++) {
      let x = new Array();
  
      if(i == 1){
        lista.push(['CODIGO DE INGRESO # ' + i ,{qr: this.entrada.codigo}]);
      }
      else{
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['','']);
        lista.push(['CODIGO DE INGRESO # ' + i, {qr: this.entrada.codigo.concat(i.toString())}]);
      }
   
    }

    var dd = {
      content: [
              {text: 'Entrada digital ' + this.evento.nombre, style: 'headerCentro'},
          {
          alignment: '',
          columns: [
              
            
            {
            width:130,
              type: 'none',
          ul: [
            {text: 'FECHA:', style: 'headList'},
            {text: 'ORGANIZADOR:', style: 'headList'},
            {text: 'LUGAR:', style: 'headList'},
          {text: 'COMPRADOR:', style: 'headList'},
          {text: 'TELEFONO:', style: 'headList'},
          ],
          
        },  
            {
            type: 'none',
            width:270,
            
          ul: [
            {text: this.datePipe.transform(this.entrada.fechaEvento, 'EEEE d MMMM yyyy h:mm a'), style: 'headList2'},
              {text: this.evento.organizadorNombre, style: 'headList2'},
             {text: this.evento.lugar, style: 'headList2'},
               
               {text: this.entrada.nombre, style: 'headList2'},
               {text: this.entrada.telefono, style: 'headList2'},
          ],
          
        },
            
              
                  {
          image: await this.getBase64ImageFromURL('../../../assets/easy4.png'),
          width: 120,
          height: 120,
        },
            
          ]
        },
          
      
      
        {text: '\nDetalle de Compra', style: 'header'},
        
        {
          style: 'tableExample',
          table: {
            body: [
              ['CANTIDAD', 'DETALLE', 'TOTAL'],
              [this.entrada.cantidad, this.entrada.fase, this.totales.totalPagar],
            
            ]
          }
        },
          {text: 'Codigos de Ingreso', style: 'header'},
          {
          style: 'tableExample',
          table: {
            body: lista
          }
        },
        {text: 'El Derecho de admision queda sujeto a las condiciones del establecimiento determinado para el evento, EasyTicketCR no se hace responsable en el caso que no se le permita el ingreso al establecimiento del evento. Los Reembolsos de dinero quedan a cargo del organizador del evento', style: 'headerCtr'},
        
      ],
      styles: {
        header: {
          bold: true,
          fontSize: 15,
             margin: [0,5,0,10],
             color: '#079a92',
        },
          headList: {
          bold: true,
          fontSize: 10,
           
             color: '#079a92',
        },
          headList2: {
          bold: false,
          fontSize: 10,
           
             
        },
          headerCtr: {
          bold: true,
          fontSize: 8,
            alignment: 'center',
             margin: [0,40,0,30]
        },
          headerCentro: {
          bold: true,
          fontSize: 15,
            alignment: 'center',
             margin: [0,0,0,30],
             color: '#079a92',
        },
      },
      defaultStyle: {
        fontSize: 12
      },
        tableExample: {
        
        },
      
    }

    const pdfDocGenerator = pdfMake.createPdf(dd);
    pdfDocGenerator.getBase64((data) => {
   
      this.convertirAbits(data)
    });
  }
    async createPdfFree() {
      let entradaNombre = ''
      if(this.promoValida){
          entradaNombre = this.codigoPromo;
      }
      else{
        entradaNombre = this.fase.fase;
      }
      let lista = [];
      for (let i = 1; i <= parseInt(this.entrada.cantidad); i++) {
        let x = new Array();
    
        lista.push({text: 'QR # ' + i, alignment: 'center'});
        if(i == 1){
          lista.push({qr: this.entrada.codigo, alignment: 'center', margin: [ 0, 5, 0, 5 ]});
        }
        else{
          lista.push({qr: this.entrada.codigo.concat(i.toString()), alignment: 'center', margin: [ 0, 5, 0, 5 ]});
        }
   
      lista.push({text: '       ', alignment: 'center'});
     
  
      }
        const colors = [ '#CD5C5C', '#F08080', '#008000' ];
        var docDefinition = {
          
          content: [
            { 
              image: await this.getBase64ImageFromURL('../../../assets/easy4.png'), 
              width: 200, 
              alignment: 'center' },
            { text: ' ', style: 'subheader' },
            { text: this.evento.nombre + ' By ' + this.evento.organizadorNombre,  style: 'header' },
            { text: '----------------------------------------------------------', style: 'subheader' },
            { text: 'INVITADA(O) ESPECIAL: ' + this.entrada.nombre  , style: 'header' },
            { text: '----------------------------------------------------------', style: 'subheader' },
            { text: '', style: 'subheader' },
            { text: 'LOCATION: ' + this.evento.lugar, style: 'subheader' },
            { text: '----------------------------------------------------------', style: 'subheader' },
            { text: '', style: 'subheader' },
            { text: ' ', style: 'subheader' },
            { text: ' ', style: 'subheader' },
            { text: 'VALIDO PARA 1 PERSONA: ', style: 'subheader' },
           { text: ' ', style: 'subheader' },
           { text: ' ', style: 'subheader' },
           { text: ' ', style: 'subheader' },
           { text: 'Evento exclusivo para mayores de 18 años', style: 'subheader2' },
       
            { text: ' ', style: 'subheader' },
           
           
            {
  
              columns: [
                {},
                {
                  ul: lista
                },
                {}
              ]
             
              // optional space between columns
             
            },
          ],
          style: 'fondo',
          styles:
           {
    
            fondo: {
              fillColor: '#d13576',
              color: '#555555',
            },
            header: {
              fontSize: 15,
              bold: true,
              alignment: 'center',
              color: '#2c5103',
            },
            subheader: {
              fontSize: 14,
              bold: true,
              alignment: 'center',
              color: '#2c5103'
              
            },
            subheader2: {
              fontSize: 9,
              bold: true,
              alignment: 'center',
              color: '#2c5103'
              
            },
            texto2: {
              
              alignment: 'center',
              color: '#079a92',
            },
    
            
            
            story: {
              italic: true,
              alignment: 'center',
              width: '50%',
            },
            rightme:
            {   
                alignment: 'center'
            }
          }
        }
     
        
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBase64((data) => {
       
          this.convertirAbits(data)
        });
       
        //this.pdfObj = pdfMake.createPdf(docDefinition);
        //this.downloadPdf();
        
      }
  

    pruebaPdf(){
      var docDefinition = {
        
        content: [
          { text: 'ENTRADA DIGITAL ' + this.evento.nombre, style: 'header' },
          { text: '----------------------------------------------------------', style: 'subheader' },
          { text: '', style: 'subheader' },
          { text: 'LUGAR: ' + this.evento.lugar, style: 'subheader' },
          { text: '----------------------------------------------------------', style: 'subheader' },
          { text: '', style: 'subheader' },
          { text: 'ORGANIZADOR: ' + this.evento.organizadorNombre, style: 'subheader' },
          { text: '----------------------------------------------------------', style: 'subheader' },
          { text: '', style: 'subheader' },
          { text: '', style: 'subheader' },
   
          { text: 'Cliente: ' + this.entrada.nombre +'  Telefono: ' + this.entrada.telefono, style: 'subheader' },
          { text: ' ', style: 'subheader' },
   
        
      
          { text: ' ', style: 'subheader' },
   
          { text: 'Cantidad: ', style: 'subheader' },
          { text:  this.entrada.cantidad  + ' Entradas', style: 'texto2' },
         ,
         { text: ' ', style: 'subheader' },
         { text: ' ', style: 'subheader' },
         { text: ' ', style: 'subheader' },
         { text: 'El cliente Debe presentar la cedula de identidad para reclamar sus entradas en puerta', style: 'subheader2' },
         { text: 'Si la cantidad de entradas es mayor a 1 las personas deberan entrar JUNTAS al evento', style: 'subheader2' },
         { text: 'Esta entrada no tiene validez hasta comprobarse la autenticidad del comprobante sinpe movil', style: 'subheader2' },
          { text: ' ', style: 'subheader' },
         
         
          {

            columns: [
              {},
              {
              
              },
              {}
            ]
           
            // optional space between columns
           
          },
        ],
        style: 'fondo',
        styles:
         {
  
          fondo: {
            fillColor: '#d13576',
            color: '#555555',
          },
          header: {
            fontSize: 15,
            bold: true,
            alignment: 'center',
            color: '#d13576',
          },
          subheader: {
            fontSize: 14,
            bold: true,
            alignment: 'center',
            color: '#3b3c57'
            
          },
          subheader2: {
            fontSize: 9,
            bold: true,
            alignment: 'center',
            color: '#3b3c57'
            
          },
          texto2: {
            
            alignment: 'center',
            color: '#d13576',
          },
  
          
          
          story: {
            italic: true,
            alignment: 'center',
            width: '50%',
          },
          rightme:
          {   
              alignment: 'center'
          }
        }
      }

    }
    

   async convertirAbits(datosImg) {
       
      let sliceSize=512;
      let contentType='application/pdf'
      let byteCharacters = atob(datosImg);
      let byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, {type: contentType});
     
      this.subirArchivo2(blob);

    }
   
    // async downloadPdf() {
    //   const loading = await this.loadingController.create({
    //     cssClass: 'my-custom-class',
    //     message: 'Validando...',
      
    //   });
    //   await loading.present();
    //   setTimeout(() => {
    //     this.pdfObj.download('Entrada: ' + this.entrada.nombre);
    //     this.modal.dismiss();
    //     loading.dismiss();
     
    //   }, 2000);

    // }

    validarTelefono(event){
      
      if(this.entrada.telefono){
        this.selectFile(event);
      }
      else{
        this.alertaTelefono();
      }
    }

    async alertaTelefono() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        header: 'Error',
        message: 'Para poder seleccionar imagen favor ingresar un numero de telefono',
        buttons: ['Aceptar'],
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
  
    }
    async alertaCorreoAviso() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        header: 'Aviso de Email',
        message: 'Una vez realizada la compra recuerda revisar tu email en la carpeta spam o correo no deseado en el caso de no recibir tu entrada despues de 24 Horas',
        buttons: ['Aceptar'],
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
  
    }
    async alertaEntradas() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        header: 'Mensaje',
        message: 'No hay entradas disponibles en este momento',
        buttons: ['Aceptar'],
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
   
    }

    async alertaCodigo() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        header: 'Error',
        message: 'Codigo no existe!',
        buttons: ['Aceptar'],
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
     
    }
 

    async alertaErrorPagoTarjeta() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        header: 'Error',
        message: 'El pago con tarjeta no se realizo correctamente, seleccione otro metodo de pago o intente de nuevo',
        buttons: ['Aceptar'],
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
    
    }
    async alertaTerminos() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        header: 'EasyTicketCR',
        message: 'Para poder realizar la compra debe aceptar los Terminos y Condiciones',
        buttons: ['Aceptar'],
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
     
  
    }
  

    async alertaCompra() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        header: 'Entradas apartadas con exito',
        message: 'Tus entradas llegaran al correo electronico ingresado despues de realizarse la validacion de los datos',
        buttons: ['Aceptar'],
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
     
  
    }

    async modalMusic(){
      let css = '';
          const modal = await this.md3.create({
        component: PlayerComponent,
        backdropDismiss: false,
        cssClass: css,
        componentProps: {
          evento: this.evento,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
    }
  
  
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
    
        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
    
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
    
          var dataURL = canvas.toDataURL("image/png");
    
          resolve(dataURL);
        };
    
        img.onerror = error => {
          reject(error);
        };
    
        img.src = url;
      });
    }

    async imageFromUrl() {
      var res = await fetch(this.evento.img);
      var blob = await res.blob();
    
      return new Promise((resolve, reject) => {
        var reader  = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);
    
        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      })
    }
    cerrar(){
      this.modal.dismiss();
    }

    async validarTerminos(){
      if(this.terminos == true){
        this.guardar();
      }
      else{
        this.alertaTerminos();
      }
    }


    async guardar(){
      if(this.tipoEntrda){
        this.entrada.fase = this.fase.fase + ' - ' + this.tipoEntrda
      }
      if(this.entrada.codigo){
       
      }
      else{
        this.entrada.codigo = this.entradaService.crearId();
        
      }
     
      
      if(this.lider){
        this.entrada.lider = this.lider;
      }
      else{
       
      }
      if(!this.evento.tarjeta && !this.evento.sinpe){
        await this.createPdfFree();
      }
      else
      {
        await this.createPdfNew();
      }

      
      let index = this.evento.fases.findIndex(f => f.fase == this.fase.fase);
      this.evento.fases[index].cantidad = this.evento.fases[index].cantidad - parseInt(this.entrada.cantidad);
      this.eventoServ.update(this.evento, this.evento.id);
      
    }


    async selectFile(event){
      if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
          this.nombreArchivo = event.target.files[i].name;
          this.datosFormulario.delete('archivo');
         await this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
          this.subirArchivo();
        }
      } else {
        this.mensajeArchivo = 'No hay un archivo seleccionado';
      }
     
    }
    
    async subirArchivo() {
      let id  = this.entradaService.crearId();
      this.entrada.codigo = id;
      const loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Subiendo comprobante...',
    
      });
      await loading.present();
      let archivo = this.datosFormulario.get('archivo');
      let referencia = this.afStorage.ref('eventos/' + this.evento.id + '/' + this.entrada.telefono.toString() + '-' + this.entrada.codigo);
      await referencia.put(archivo);
      referencia.getDownloadURL().subscribe(data =>{
        if(data){
          this.entrada.sinpe = data;
        }
      });
      this.limpiarCarga();
      loading.dismiss();
    }
    async subirArchivo2(data) {
      
      const loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Cargando...',
    
      });
      await loading.present();
    
      let referencia = this.afStorage.ref('pdfs/' + this.evento.id + '/' + this.entrada.codigo + '.pdf');
      await referencia.put(data);
      referencia.getDownloadURL().subscribe(data =>{
        if(data){
          this.entrada.linkPdf = data;
          if(this.metodoP == 'tarjeta'){
          
            this.entrada.metodoP = 'tarjeta';
            this.entradaService.crearSubs(this.entrada);
          }
          this.entrada.reference = this.entrada.codigo;
          this.entradaService.add(this.entrada, this.evento.id);
        
        }
      });
      this.limpiarCarga();
      loading.dismiss();
      this.modal.dismiss();
      if(this.evento.modalMusic){
        this.modalMusic();
      }
      else{
        this.alertaCompra();
      }
   
    }

    async subirPdf(pdfDoc) {
     
      const loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Subiendo comprobante...',
    
      });
      await loading.present();
      let archivo = pdfDoc;
      let referencia = this.afStorage.ref(this.evento.id + '/' + this.entrada.telefono.toString());
      await referencia.put(archivo);
      referencia.getDownloadURL().subscribe(data =>{
        if(data){
          this.entrada.linkPdf = data;
        }
      });
      this.limpiarCarga();
      loading.dismiss();
    }
    
    
    limpiarCarga(){
      this.mensajeArchivo = 'No hay un archivo seleccionado';
      this.nombreArchivo = '';
      this.datosFormulario.delete('archivo');
    
    }

    terminosPago(){
      if(this.terminos == true){
        this.modalPago();
      }
      else{
        this.alertaTerminos();
      }
    }
    async modalPago(){
      
      let css = 'class-modal';
      const modal = await this.modal2.create({
    component: ModalPagoComponent,
    backdropDismiss: false,
    cssClass: css,
    componentProps: {
      evento: this.evento,
      organizador: this.organizador,
      entrada: this.entrada,
      tc: this.evento.tipoCambio,
      comsion: this.evento.comision,
    },
  });
  await modal.present();
  const { data } = await modal.onDidDismiss();
  if(data){

    this.guardar();
  }
  else{
    this.alertaErrorPagoTarjeta();
  }
  }
  
}
