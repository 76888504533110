import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DatosService } from 'src/app/services/datos.service';
import { EntradasService } from 'src/app/services/entradas.service';

@Component({
  selector: 'myApp-login-cliente',
  templateUrl: './login-cliente.component.html',
  styleUrls: ['./login-cliente.component.scss'],
})
export class LoginClienteComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  usuario = '';
  password = '';
  constructor(private entradaServ: EntradasService,
              private mdc: ModalController,
              private datosService: DatosService) { }

  ngOnInit() {
  }


  login(){
    this.subscriptions.push(
      this.entradaServ.getUsuarioCliente(this.usuario).subscribe(data =>{
    
        if(data){
          if(data.password == this.password){
            this.cerrarLogin(data);
            this.datosService.userLogin = data;
            this.unsubs();
          }
          else{
            alert('Password Incorrecto');
            this.unsubs();
          }
        }
        else{
          alert('Usuario Incorrecto');
          this.unsubs();
        }
      })
    )
  }
cerrar(){
  this.mdc.dismiss();
}
  cerrarLogin(data){
    this.mdc.dismiss({
      usuario: data,
    })
  }
  unsubs(){
    this.subscriptions.forEach(data =>{
      data.unsubscribe();
    })
  }

    
  }


