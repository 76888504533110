import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-detalle-pedido',
  templateUrl: './detalle-pedido.component.html',
  styleUrls: ['./detalle-pedido.component.scss'],
})
export class DetallePedidoComponent implements OnInit {

  constructor(private modalController: ModalController) { }
@Input() pedido;
  ngOnInit() {}


  cerrar(){
    this.modalController.dismiss();
  }

}
