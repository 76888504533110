import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, Platform, LoadingController, AlertController } from '@ionic/angular';


import { AngularFireStorage } from 'angularfire2/storage';

import { OrganizadorService } from '../../services/organizador.service';
import { EventoService } from '../../services/evento.service';
import { Organizador } from 'src/app/models/organizador';

@Component({
  selector: 'myApp-nuevo-organizador',
  templateUrl: './nuevo-organizador.component.html',
  styleUrls: ['./nuevo-organizador.component.scss'],
})
export class NuevoOrganizadorComponent implements OnInit {


@Input() organizador: Organizador
public mensajeArchivo = 'No hay un archivo seleccionado';
public datosFormulario = new FormData();
public nombreArchivo = '';
public URLPublica = '';
public porcentaje = 0;
public finalizado = false;



constructor(private modal: ModalController,
  private organizadorService: OrganizadorService,
  private loadingController: LoadingController,
  private organizadorServ: OrganizadorService,
  private alertController: AlertController,
  private afStorage: AngularFireStorage) { }

  ngOnInit() {}


  async selectFile(event){
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
       await this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        this.subirArchivo();
      }
    } else {
      this.mensajeArchivo = 'No hay un archivo seleccionado';
    }
   
  }

  cerrar(){
    this.modal.dismiss();
  }
  
  async subirArchivo() {
    
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Subiendo imagen...',
  
    });
    await loading.present();
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.afStorage.ref('organizadores/' + this.organizador.id);
    await referencia.put(archivo);
    referencia.getDownloadURL().subscribe(data =>{
      if(data){
        this.organizador.img = data;
      }
    });
    this.limpiarCarga();
    loading.dismiss();
  }

  limpiarCarga(){
    this.mensajeArchivo = 'No hay un archivo seleccionado';
    this.nombreArchivo = '';
    this.datosFormulario.delete('archivo');
  }

  guardar(){
    this.organizadorServ.add(this.organizador);
    this.modal.dismiss();
  }

  
}
