import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from 'angularfire2/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { ThrowStmt } from '@angular/compiler';
import { Articulo } from '../models/articulo';
import { Cia } from '../models/cia';
import { Entradas } from '../models/entradas';
import { Organizador } from '../models/organizador';

@Injectable({
  providedIn: 'root'
})
export class OrganizadorService {
  private usuariosCollection: AngularFirestoreCollection<Organizador>;
  private userScannCollec: AngularFirestoreCollection<any>;
  private usuarios: Observable<Organizador[]>;
  private usuario: BehaviorSubject<string>;

  constructor(public db: AngularFirestore) {
    
    this.usuariosCollection = db.collection<Organizador>('tblOrganizadores');

    this.usuarios = this.usuariosCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a:any) => {
          const data = a.payload.doc.data();
          const key = a.payload.doc.id;
          return { key, ...data };
        });
      })
    );
  }

 
  crearId(){
   return this.db.createId();
  }

  getUsuarios() {
    return this.usuarios;
  }

  getUsuario(id) {
    return this.usuariosCollection.doc<Organizador>(id).valueChanges();
  }
  getUsuarioScanner(id) {
    
    this.userScannCollec = this.db.collection<any>('usuariosScan', (ref)=>
      ref);
      return this.userScannCollec.doc<any>(id).valueChanges();
  }
  getOrganizadorId(id){
    let results:any = []
    return results = this.db.collection<Organizador>('tblOrganizadores', (ref) =>
      ref.where('id', '==', id)).valueChanges();


  }
  update(Cia: Organizador, id: string) {
    return this.usuariosCollection.doc(id).update(Cia);
  }

  add(Cia: Organizador) {
  
    return this.usuariosCollection.doc(Cia.id).set(Cia);
  }

  remove(id) {
    return this.usuariosCollection.doc(id).delete();
  }
}