import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StorageService } from './services/storage.service';
import { DatosService } from './services/datos.service';
import { Subscription } from 'rxjs';
import { EntradasService } from './services/entradas.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private subscriptions: Subscription[] = [];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: StorageService,
    private nav: NavController,
    private datosService: DatosService,
    private entradaService: EntradasService,

  ) {
    this.initializeApp();
    
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      this.splashScreen.hide();
      //this.verificar();
      this.obtenerConfiguraciones();

    });
  }


 async obtenerConfiguraciones(){
    this.subscriptions.push(
      this.entradaService.getDatosTickets('configs').subscribe((data:any) =>{
        if(data){
          this.datosService.uid = data.uid;
          this.datosService.wsk = data.wsk;
          this.datosService.sandbox = data.sandbox;
        }
      })
    )
  }


  verificar(){
    if(this.datosService.evento){
      //this.nav.navigateRoot('eventos')
    }
    else{
      this.nav.navigateRoot(['eventos']);
    }
  }

 
}
