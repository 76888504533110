import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/__ivy_ngcc__/ngx';
import { LoginClienteComponent } from '../login-cliente/login-cliente.component';
import { DatosService } from 'src/app/services/datos.service';
import { PerfilComponent } from '../perfil/perfil.component';
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  @Input() celular;
  constructor(private modal: ModalController,
              private md3: ModalController,
              private iab: InAppBrowser,
              public datoServ: DatosService,
              private nav: NavController) { }

  ngOnInit() {}



  cerrar(){
    this.modal.dismiss();
  }


  openWaEasy(){
    this.iab.create('https://api.whatsapp.com/send?phone=50660176030');
  }

  openInstaEasy(){
    this.iab.create('https://www.instagram.com/easyticketcr/');
  }

  navInicio(){
    this.nav.navigateRoot(['eventos']);
    this.modal.dismiss();
  }
  async loginCliente(){
       
    let css = '';
    if(this.celular){

    }
    else{
      css = 'modal-habitacion'
    }
      const modal = await this.md3.create({
    component: LoginClienteComponent,
    backdropDismiss: false,
    cssClass: css,
    componentProps: {
      
    },
  });
  await modal.present();
  const { data } = await modal.onDidDismiss();
  if(data){
    if(data.usuario){
      this.validarDatosUsuario(data.usuario);
    }
   
  }
  else{
    console.log('sin usuario');
  }
}
async perfil(){
       
  let css = '';
  if(this.celular){

  }
  else{
    css = 'modal-habitacion'
  }
    const modal = await this.md3.create({
  component: PerfilComponent,
  backdropDismiss: false,
  cssClass: css,
  componentProps: {
    
  },
});
await modal.present();
const { data } = await modal.onDidDismiss();

}

validarDatosUsuario(data){
  this.datoServ.userLogin = data;
  this.perfil();
}

}
