import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { DatosService } from 'src/app/services/datos.service';

@Component({
  selector: 'app-sidemenu-admin',
  templateUrl: './sidemenu-admin.component.html',
  styleUrls: ['./sidemenu-admin.component.scss'],
})
export class SidemenuAdminComponent implements OnInit {
  @Input() sede;
  constructor(private modal: ModalController,
              public datosService: DatosService,
              private nav: NavController,) { }

  ngOnInit() {}


  cerrar(){
    this.modal.dismiss();
  }


  navProductos(){
    this.nav.navigateRoot(['articulos/']);
    this.modal.dismiss();
  }

  navInicio(){
    this.nav.navigateRoot(['principal']);
    this.modal.dismiss();
  }

  navPedidos(){
    this.nav.navigateRoot(['pedidos']);
    this.modal.dismiss();
  }
  navReportes(){
    this.nav.navigateRoot(['reportes']);
    this.modal.dismiss();
  }
  navConfig(){
    this.nav.navigateRoot(['configuraciones/']);
    this.modal.dismiss();
  }


}
