export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyBXWyvksWoPpvRvsAGHWxllOkXqf5INwFc",
    authDomain: "easy-ticketcr.firebaseapp.com",
    projectId: "easy-ticketcr",
    storageBucket: "easy-ticketcr.appspot.com",
    messagingSenderId: "904589612860",
    appId: "1:904589612860:web:97f81d668d388022e7f039"
  },

  
};
