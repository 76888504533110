import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { InAppBrowser } from '@ionic-native/in-app-browser/__ivy_ngcc__/ngx';
import { EntradasService } from 'src/app/services/entradas.service';
import { DatePipe } from '@angular/common';
import { Entradas } from 'src/app/models/entradas';

@Component({
  selector: 'myApp-visor-entrada',
  templateUrl: './visor-entrada.component.html',
  styleUrls: ['./visor-entrada.component.scss'],
})
export class VisorEntradaComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  @Input() entrada;
  @Input() evento;
  @Input() idOriginal;
  constructor(
              private modal: ModalController,
              private iab: InAppBrowser,
              private entradaService: EntradasService,
              private loading: LoadingController,
              private datePipe: DatePipe,
              private alertController: AlertController,
              private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.entrada.codigoEvento = this.evento;
    console.log(this.entrada);

    this.revisarIngresos();
  }

  async alertaIngreso(ing) {
    const alert = await this.alertController.create({
      header: 'Alerta',
      message: 'Este codigo ya fue ingresado a las - ' + this.datePipe.transform(ing.fechaIngreso, 'hh:mm a'),
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  async alertaError() {
    const alert = await this.alertController.create({
      header: 'Error',
      message: 'Ingrese motivo de rechazo',
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  async alertaEliminada() {
    const alert = await this.alertController.create({
      header: 'Entrada eliminada',
      message: 'El cliente va ser notificado a su correo electronico',
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  async validarSinpe(){
    if(this.entrada.sinpeValido){
      if(this.entrada.correoEnviado){
        console.log('Correo ya fue enviado')
        if(this.entrada.correoEnviado == 'Correo enviado con exito'){
          console.log('Ya se envio correo');
        }
        if(this.entrada.correoEnviado == 'Error al enviar correo'){
          this.entradaService.crearSubs(this.entrada);
          console.log('Correo con error, re enviando');
        }
      }
      else{
        this.entradaService.crearSubs(this.entrada);
      }
  
    }
    else{
        console.log('Sin validar Sinpe');
    }

  }

  async revisarIngresos(){
    let contador = 0;
    let ing: any;
    this.entrada.qrs.forEach(ingreso => {
        if(ingreso.codigo == this.idOriginal){
            contador += 1;
            ing = ingreso;
            this.alertaIngreso(ing);
        }
        else{

        }
    });
    
  }

 async reenviarCorreo(){
  const toast = await this.toastController.create({
    message: 'Correo Reenviado',
    duration: 2000
  });
  toast.present();
    this.entradaService.crearSubs(this.entrada);
  }

  async alertaEliminar() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirmar',
      message: 'Desea eliminar este registro?',
      inputs: [
        {
          name: 'motivo',
          type: 'text',
          placeholder: 'Ingrese motivo de rechazo',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
           
          },
        },
        {
          text: 'Aceptar',
          handler: (data) => {
            if(data.motivo){
                console.log(data.motivo);
                this.eliminarEntrada(data.motivo);
            }
            else
            {
              this.alertaError();
            }
          },
        },
      ],
    });

    await alert.present();
  }


  eliminarEntrada(motivo){
    let ent = this.entrada;
    ent.motivo = motivo;
    this.entradaService.crearRechazo(ent).then(data =>{
      this.entradaService.borrarPorOrganizador(this.evento, ent.codigo);
      this.alertaEliminada();
      this.modal.dismiss();
    })
  }

  cerrar(){
    this.modal.dismiss();
  }

  abrirLink(){
      this.iab.create(this.entrada.sinpe);
  }
  openWa(){
    this.iab.create('https://api.whatsapp.com/send?phone=506' + this.entrada.telefono);
  }
  openWaEntrada(){
    this.iab.create('https://wa.me/506' + this.entrada.telefono + '?text=Tu entrada para el evento: ' + this.entrada.linkPdf);
  }
  pdf(){
    this.iab.create(this.entrada.linkPdf);
  }
  guardar(){
    this.entrada.codigoEvento = this.evento;
    this.entradaService.update(this.entrada, this.evento);
    this.validarSinpe();
    this.modal.dismiss();
  }

  realizarIngreso(){
    this.entrada.fechaIngreso = new Date().toISOString();
    this.entrada.ingreso = true;
    this.entrada.qrs.push(
      {
        fechaIngreso: new Date().toISOString(),
        codigo: this.idOriginal,
      }
    );
    this.guardar();
  }

  
}
