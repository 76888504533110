import { Injectable } from '@angular/core';
import { Organizador } from '../models/organizador';
import { Usuario } from '../models/usuario';
import { MessageClient } from "cloudmailin";
@Injectable({
  providedIn: 'root'
})
export class DatosService {
client: any;
userLogin:any;
evento:any;
uid = "";
wsk = "";
sandbox = true;
organizador:any;
faseEvento:any;
  constructor() { 
  
  }
  public usuario: Organizador;
  public acceso = false;



 
}







