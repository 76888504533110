import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage";

import { AngularFireModule } from "angularfire2";
import { AngularFirestoreModule } from "angularfire2/firestore";

import { AngularFireStorageModule } from "@angular/fire/storage";
import { environment } from "src/environments/environment";
import { ArticulosComponent } from "./components/articulos/articulos.component";
import { FormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { SidemenuComponent } from "./components/sidemenu/sidemenu.component";
import { ModalArtComponent } from "./components/modal-art/modal-art.component";

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { SidemenuAdminComponent } from "./components/sidemenu-admin/sidemenu-admin.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ModalAgregarComponent } from "./components/modal-agregar/modal-agregar.component";
import { DetallePedidoComponent } from "./components/detalle-pedido/detalle-pedido.component";
import { EntradaClienteComponent } from "./components/entrada-cliente/entrada-cliente.component";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/__ivy_ngcc__/ngx";
import { CamaraComponent } from "./components/camara/camara.component";
import { InAppBrowser } from '@ionic-native/in-app-browser/__ivy_ngcc__/ngx';
import { LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { VisorEntradaComponent } from "./components/visor-entrada/visor-entrada.component";
import { VisorEntradaIngresoComponent } from "./components/visor-entrada-ingreso/visor-entrada-ingreso.component";
import { FaseComponent } from "./components/fase/fase.component";
import { NuevoOrganizadorComponent } from "./components/nuevo-organizador/nuevo-organizador.component";
import { NuevoEventoComponent } from "./components/nuevo-evento/nuevo-evento.component";
import { SeleccionEntradaComponent } from "./components/seleccion-entrada/seleccion-entrada.component";
import { ModalPagoComponent } from "./components/modal-pago/modal-pago.component";
import { PlayerComponent } from "./components/player/player.component";
import { LoginClienteComponent } from "./components/login-cliente/login-cliente.component";
import { PerfilComponent } from "./components/perfil/perfil.component";
registerLocaleData(es);



@NgModule({
  declarations: [
    AppComponent,
    NuevoOrganizadorComponent,
    ArticulosComponent,
    VisorEntradaComponent,
    CamaraComponent,
    SidemenuComponent,
    ModalArtComponent,
    SidemenuAdminComponent,
    ModalAgregarComponent,
    DetallePedidoComponent,
    EntradaClienteComponent,
    VisorEntradaIngresoComponent,
    FaseComponent,
    NuevoEventoComponent,
    SeleccionEntradaComponent,
    ModalPagoComponent,
    PlayerComponent,
    LoginClienteComponent,
    PerfilComponent

  ],
  entryComponents: [PerfilComponent,LoginClienteComponent,ModalPagoComponent,SeleccionEntradaComponent,NuevoEventoComponent,NuevoOrganizadorComponent,FaseComponent,VisorEntradaIngresoComponent,VisorEntradaComponent,CamaraComponent,EntradaClienteComponent,DetallePedidoComponent,ModalAgregarComponent,SidemenuAdminComponent,ArticulosComponent, SidemenuComponent, ModalArtComponent],
  imports: [
    BrowserModule,

    IonicModule.forRoot(),
    AppRoutingModule,
    
    AngularFireStorageModule,
    CommonModule,
    HttpClientModule,
    BackButtonDisableModule.forRoot(),
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  exports: [ArticulosComponent],
  providers: [
    {provide: LOCALE_ID, useValue: 'es-*' },
    BarcodeScanner,
    DatePipe,
    EmailComposer,
    HttpClient,
    StatusBar,
    InAppBrowser,
    SplashScreen,
    IonicStorageModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
