import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from 'angularfire2/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { ThrowStmt } from '@angular/compiler';
import { Articulo } from '../models/articulo';
import { Cia } from '../models/cia';
import { Entradas } from '../models/entradas';
import { Evento } from '../models/evento';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  private usuariosCollection: AngularFirestoreCollection<Evento>;

  private usuarios: Observable<Evento[]>;
  private usuario: BehaviorSubject<string>;

  constructor(public db: AngularFirestore) {
   
    this.usuariosCollection = db.collection<Evento>('tblEventos');

    this.usuarios = this.usuariosCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a:any) => {
          const data = a.payload.doc.data();
          const key = a.payload.doc.id;
          return { key, ...data };
        });
      })
    );
  }

 

  getUsuarios() {
    return this.usuarios = this.db.collection<Evento>('tblEventos', (ref) =>
    ref.orderBy('fecha')).valueChanges();
  }

  getUsuario(id) {
    return this.usuariosCollection.doc<Evento>(id).valueChanges();
  }

  getEventoUsuario(id){
    return this.usuarios = this.db.collection<Evento>('tblEventos', (ref) =>
    ref.where('organizadorId','==', id).orderBy('fecha')).valueChanges();
  }


getEvento(id) {
  this.usuariosCollection = this.db.collection<Evento>('tblEventos');
  return this.usuariosCollection.doc<Evento>(id).valueChanges();
}
  update(Cia: Evento, id: string) {
    return this.usuariosCollection.doc(id).update(Cia);
  }

  add(Cia: Evento) {
    
    const id = this.db.createId();
    Cia.id = id;
    return this.usuariosCollection.doc(id).set(Cia);
  }

  remove(id) {
    return this.usuariosCollection.doc(id).delete();
  }
}