import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  
  {
    path: '',
    redirectTo: 'eventos',
    pathMatch: 'full'
  },
  {
    path: 'evento',
    loadChildren: () => import('./evento/evento.module').then( m => m.EventoPageModule)
  },
  {
    path: 'evento/:id',
    loadChildren: () => import('./evento/evento.module').then( m => m.EventoPageModule)
  },
  {
    path: 'eventos',
    loadChildren: () => import('./eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'eventos/:id',
    loadChildren: () => import('./eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'entrada-cliente',
    loadChildren: () => import('./entrada-cliente/entrada-cliente.module').then( m => m.EntradaClientePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'organizador',
    loadChildren: () => import('./organizador/organizador.module').then( m => m.OrganizadorPageModule)
  },
  {
    path: 'organizador/:id',
    loadChildren: () => import('./organizador/organizador.module').then( m => m.OrganizadorPageModule)
  },
  {
    path: 'evento-organizador',
    loadChildren: () => import('./evento-organizador/evento-organizador.module').then( m => m.EventoOrganizadorPageModule)
  },
  {
    path: 'evento-organizador/:id',
    loadChildren: () => import('./evento-organizador/evento-organizador.module').then( m => m.EventoOrganizadorPageModule)
  },
  {
    path: 'editar-evento',
    loadChildren: () => import('./editar-evento/editar-evento.module').then( m => m.EditarEventoPageModule)
  },
  {
    path: 'editar-evento:/:id',
    loadChildren: () => import('./editar-evento/editar-evento.module').then( m => m.EditarEventoPageModule)
  },
  {
    path: 'evento-edit',
    loadChildren: () => import('./evento-edit/evento-edit.module').then( m => m.EventoEditPageModule)
  },
  {
    path: 'evento-edit/:id',
    loadChildren: () => import('./evento-edit/evento-edit.module').then( m => m.EventoEditPageModule)
  },
  {
    path: 'evento-edit/:id/:admin',
    loadChildren: () => import('./evento-edit/evento-edit.module').then( m => m.EventoEditPageModule)
  },
  {
    path: 'admin-organizador',
    loadChildren: () => import('./admin-organizador/admin-organizador.module').then( m => m.AdminOrganizadorPageModule)
  },
  {
    path: 'evento-lider',
    loadChildren: () => import('./evento-lider/evento-lider.module').then( m => m.EventoLiderPageModule)
  },
  {
    path: 'evento-lider/:id',
    loadChildren: () => import('./evento-lider/evento-lider.module').then( m => m.EventoLiderPageModule)
  },
  {
    path: 'admin-evento',
    loadChildren: () => import('./admin-evento/admin-evento.module').then( m => m.AdminEventoPageModule)
  },
  {
    path: 'admin-evento/:id',
    loadChildren: () => import('./admin-evento/admin-evento.module').then( m => m.AdminEventoPageModule)
  },
  {
    path: 'confirmacion',
    loadChildren: () => import('./confirmacion/confirmacion.module').then( m => m.ConfirmacionPageModule)
  },
 
  {
    path: 'confirmacion/:idTrans/:idDocumento',
    loadChildren: () => import('./confirmacion/confirmacion.module').then( m => m.ConfirmacionPageModule)
  },
  {
    path: 'compra',
    loadChildren: () => import('./compra/compra.module').then( m => m.CompraPageModule)
  },
  {
    path: 'login-scanner',
    loadChildren: () => import('./login-scanner/login-scanner.module').then( m => m.LoginScannerPageModule)
  },
  {
    path: 'scanner',
    loadChildren: () => import('./scanner/scanner.module').then( m => m.ScannerPageModule)
  },
  {
    path: 'scanner/:id',
    loadChildren: () => import('./scanner/scanner.module').then( m => m.ScannerPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
