import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-agregar',
  templateUrl: './modal-agregar.component.html',
  styleUrls: ['./modal-agregar.component.scss'],
})
export class ModalAgregarComponent implements OnInit {
 @Input() articulo;
 color = '';
  constructor(private modal: ModalController,
              private alertController: AlertController) { }

  ngOnInit() {}


  agregar(c, cantidad){
    this.modal.dismiss({
      color: c,
      cantidad: cantidad
    })
  }

  async validarCantidad(c, existencias, cantidad){
    let cant = cantidad * 1;
    let exis = existencias * 1;
    if(cant>exis){
      this.alertaError();
    }
    else{
      this.agregar(c, cantidad);
    }
  }

  cerrar(){
    this.modal.dismiss(); 
  }

  async alertaCantidad(color, existencia) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Ingrese cantidad',
 
      inputs: [
        {
          name: 'cantidad',
          type: 'number',
          placeholder: '1',
          value: 1,
          
        },
       
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
         
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
         
          handler: (data) => {
           if(data.cantidad){
            this.validarCantidad(color, existencia, data.cantidad);
           }
          }
        }
      ]
    });

    await alert.present();
  }


  async alertaError() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
  
      message: 'La cantidad a facturar es mayor a la cantidad en existencias',
      buttons: ['Aceptar']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

}
